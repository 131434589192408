/* src/fonts/fonts.css */
@font-face {
    font-family: 'Fira Code';
    src: url('./Fira_Code_v5.2/woff2/FiraCode-Regular.woff2') format('woff2'),
         url('./Fira_Code_v5.2/woff/FiraCode-Regular.woff') format('woff'),
         url('./Fira_Code_v5.2/ttf/FiraCode-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Fira Code Light';
    src: url('./Fira_Code_v5.2/woff2/FiraCode-Light.woff2') format('woff2'),
         url('./Fira_Code_v5.2/woff/FiraCode-Light.woff') format('woff'),
         url('./Fira_Code_v5.2/ttf/FiraCode-Light.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Fira Code Bold';
    src: url('./Fira_Code_v5.2/woff2/FiraCode-Bold.woff2') format('woff2'),
         url('./Fira_Code_v5.2/woff/FiraCode-Bold.woff') format('woff'),
         url('./Fira_Code_v5.2/ttf/FiraCode-Bold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }